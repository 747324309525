.content {
    display: flex;
    justify-content: flex-end;
    max-height: calc(100vh - 80px - 44px - 113px);
    overflow-y: scroll;
}

.content {

    &__image {
        border-left: $border;
        border-right: $border;
        border-top: $border;
        margin-left: -$border-width;
    }

    &__text {
        padding: $padding-base;
        margin: 0;
        margin-top: -4px;
    }
}

.content-single {
    display: flex;
    border-top: $border;
    border-bottom: $border;
    margin: -$border-width 0;
}

.content-two {
    display: flex;
    border-top: $border;
    border-bottom: $border;
    margin: -$border-width 0;
    width: 50%;
}

.content-sidebar {
    width: calc(25% + #{$border-width});
    border-right: $border;

    &__item {
        border-bottom: $border;
        padding: $padding-base;

        p {
            margin: 0;

            &:first-child {
                margin-top: -$border-width;
            }
        }
    }
}

.content-right {
    width: 75%;
} 

.content-description {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__item {
        border-bottom: $border;
        padding: $padding-base;
        margin-bottom: -$border-width;

        p {
            margin: -3px 0;

            + p {
                margin-top: 11px;
            }
        }

        h2 {
            margin: -10px 0 10px 0;
        }
    }

    &__columns {
        &--two {
            display: flex;

            .column {
                width: 50%;

                +.column  {
                    padding-left: $padding-base;
                }
            }
        }
    }

    .content-column {
        width: 50%;
    }

    &--icon {
        display: flex;
    }
}

.content__box {
    width: 100%;

    h2 {
        margin: 10px 18px -7px 18px;
    }
    
    + .content__box {
        border-top: $border;
    }

    &--helper-ballot {
        border-bottom: $border;
    }
}

.content__square-icon {
    display: flex;
    justify-content: center;
    width: 75px;
    border: $border;
    margin: 0 0 (-$border-width) (-$border-width);
}