@import "css/settings.colors";
@import "css/settings.basefontsize";
@import "css/settings.fonts";
@import "css/settings.spacings";
@import "css/settings.border";

@import "css/tools.mixins";

@import "css/generic.reset";
@import "css/generic.box-sizing";

@import "css/elements.body";
@import "css/elements.typography";
@import "css/elements.forms";
@import "css/elements.lists";
@import "css/elements.links";

@import "css/components.app";
@import "css/components.header";
@import "css/components.footer";
@import "css/components.content";
@import "css/components.button";
@import "css/components.calendar";
@import "css/components.vote-add";
@import "css/components.vote-list";
@import "css/components.meeting-bar";
@import "css/components.add-to-calendar";
@import "css/components.loader";
@import "css/components.modal";

.vote-list-item {
  background-color: rgb(235, 235, 235);
}