.modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: $padding-base * 2;
    background-color: rgba($color-background, 0.7);
}

.modal {

    &__body {
        display: flex;
        width: $width-page / 2;
        border: $border;
        background-color: $color-background;
        align-items: flex-start;
        margin-bottom: -$border-width;
    }

    &__image {
        border-right: $border;
    }

    &__text {
        margin: 16px ($padding-base - 1px);
    }

    &__footer {
        background-color: $color-background;
        border: $border;
        display: flex;
        justify-content: flex-end;

        .btn {
            margin-right: -$border-width;
        }
    }
}