.btn {
    font-family: WhyteInktrapRegular, $font-fallback;
    font-weight: normal;
    font-style: normal;
    border-radius: 0;
    border: $border;
    color: $color-brand;
    font-size: rem(15px);
    cursor: pointer;
    background-color: rgba($color-brand, 0.1);
    line-height: rem(20px);
    padding: 10px 19px 11px 19px;
    margin-left: -$border-width;
    margin-top: -$border-width;
    transition: background-color 0.1s ease-in-out;
    
    &:hover {
        background-color: rgba($color-brand, 0.2);
        border-color: rgba($color-brand, 1);
    }
    
    &:focus {
        outline:0;
    }
    
    &--primary {
        color: $color-background;
        background-color: $color-brand;
        
        &:hover {
            background-color: darken($color-brand, 10%);
            border-color: darken($color-brand, 10%);
        }
    }

    &--small {
        padding: 4px 19px 4px 19px;
    }
}