ul {
    padding-left: 0;
    margin: 15px 0;
    
    li {
        list-style-type: none;
        text-indent: 0;
        padding-left: $padding-base * 2 - 1px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
            right: 0;
            width: $padding-base;
            height: $border-width;
            border-bottom: $border;
        }
    }
}