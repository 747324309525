a {
    color: $color-brand;
    text-decoration: none;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: $border-width;
        color: $color-brand;
    }
}