.loader {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: $padding-base * 2;
    background-color: rgba($color-background, 0.7);
    z-index: 100;
}

.loader-box {
    display: flex;
    width: $width-page / 2;
    border: $border;
    background-color: $color-background;
    align-items: flex-start;

    &__image {
        border-right: $border;
    }

    &__text {
        margin: 16px ($padding-base - 1px);
    }
}