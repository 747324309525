.vote {
    display: flex;
    width: 100%;
    border: $border;
    border-right: 0;
    margin-right: -$border-width;
    margin-bottom: -$border-width;
    padding: $padding-base;
    padding-right: 0;
    justify-content: space-between;
    align-items: flex-end;
    
    &__reserve-button {
        border-right: 0;
        margin-bottom: -$padding-base -$border-width;
    }

    &:first-child {
        margin-top: $padding-base;
    }
}

.vote-prop {
    display: flex;
    flex-direction: column;

    &__time {
        margin-top: -3px;
    }

    &__location {
        margin: 13px 0 -5px 0;
    }
}