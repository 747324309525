@font-face {
    font-family: WhyteInktrapRegular;
    src: url('./fonts/WhyteInktrap-Regular.woff2') format('woff2'), 
         url('./fonts/WhyteInktrap-Regular.woff') format('woff');
}

@font-face {
    font-family: MogelsbergGroteskRegular;
    src: url('./fonts/MogelsbergGrotesk-Regular.eot');
    src: url('./fonts/MogelsbergGrotesk-Regular.woff2') format('woff2'), 
         url('./fonts/MogelsbergGrotesk-Regular.woff') format('woff');
  }

$font-fallback: "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol