.header {
    border: $border;
    display: flex;
    margin: (-$border-width) (-$border-width);
    background-color: $color-background;
    z-index: 10;
    height: 113px;
}

.header-title,
.header-desc {
    padding: $padding-base;
    display: flex;
}

.header-title {
    flex-direction: column;
    align-items: flex-start;
    width: 25%;

    &__brand {
        margin-top: -3px;
    }

    &__text {
        margin: 0 -1px;
    }
}

.header-desc {
    border-left: $border;
    width: 75%;
    align-items: flex-start;

    &__icon {
        margin-right: $padding-base;
    }

    &__text {
        margin: -5px -1px; 
    }
}