.footer {
    position: relative;
    border: $border;
    margin: 0 (-$border-width) (-$border-width) (-$border-width);
    width: $width-page;
    background: $color-background;
    height: 43px;
    display: flex;
    justify-content: flex-end;

    &__part {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        margin-right: -$border-width;
    }

    &__help {
        position: absolute;
        left: -1px;
        bottom: -52px;
    }

    &--split {
        display: flex;

        .btn {
            margin-bottom: -$border-width;
        }
    }
}