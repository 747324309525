body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: WhyteInktrapRegular;
  src: url(/static/media/WhyteInktrap-Regular.510ff602.woff2) format("woff2"), url(/static/media/WhyteInktrap-Regular.efb1244d.woff) format("woff"); }

@font-face {
  font-family: MogelsbergGroteskRegular;
  src: url(/static/media/MogelsbergGrotesk-Regular.83461f4c.eot);
  src: url(/static/media/MogelsbergGrotesk-Regular.552823f1.woff2) format("woff2"), url(/static/media/MogelsbergGrotesk-Regular.3b382dc2.woff) format("woff"); }

* {
  box-sizing: border-box; }

body {
  max-width: 900px;
  margin: 0 auto; }

::-moz-selection {
  background: rgba(219, 0, 0, 0.1); }

::selection {
  background: rgba(219, 0, 0, 0.1); }

h1,
p,
span {
  -webkit-font-feature-settings: "kern" 1, "kern";
          font-feature-settings: "kern" 1, "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1,
h2 {
  font-family: WhyteInktrapRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  font-style: normal;
  font-size: 1.375rem;
  line-height: 2.125rem;
  color: #db0000; }

p,
li {
  text-align: left;
  color: #db0000;
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: MogelsbergGroteskRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

span,
label {
  font-size: 0.6875rem;
  letter-spacing: 0.03em;
  font-weight: normal;
  font-style: normal;
  color: #db0000;
  font-family: MogelsbergGroteskRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }

form {
  display: flex;
  width: 100%; }

.form-group {
  display: flex;
  flex-direction: column;
  width: 37.5%;
  margin-bottom: 20px; }
  .form-group + .form-group {
    margin-left: -1px; }
  .form-group__helper {
    margin-top: 13px;
    margin-bottom: -6px;
    line-height: 20px; }

label {
  z-index: 2;
  padding-left: 20px;
  margin-top: 37px; }

.react-datepicker__input-container {
  width: 100%; }

textarea:focus,
input:focus {
  outline: none; }

input[type="text"] {
  height: 70px;
  border: 1px solid #db0000;
  width: 100%;
  color: #db0000;
  font-size: 15px;
  padding-left: 20px;
  padding-top: 20px;
  padding: 20px;
  padding-top: 35px;
  padding-left: 19px;
  margin-top: -32px; }

ul {
  padding-left: 0;
  margin: 15px 0; }
  ul li {
    list-style-type: none;
    text-indent: 0;
    padding-left: 39px;
    position: relative; }
    ul li::before {
      content: "";
      position: absolute;
      top: 13px;
      left: 0;
      right: 0;
      width: 20px;
      height: 1px;
      border-bottom: 1px solid #db0000; }

a {
  color: #db0000;
  text-decoration: none;
  position: relative; }
  a::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 1px;
    color: #db0000; }

#app {
  border: 1px solid #db0000;
  margin-top: 40px; }

.header {
  border: 1px solid #db0000;
  display: flex;
  margin: -1px -1px;
  background-color: #ffffff;
  z-index: 10;
  height: 113px; }

.header-title,
.header-desc {
  padding: 20px;
  display: flex; }

.header-title {
  flex-direction: column;
  align-items: flex-start;
  width: 25%; }
  .header-title__brand {
    margin-top: -3px; }
  .header-title__text {
    margin: 0 -1px; }

.header-desc {
  border-left: 1px solid #db0000;
  width: 75%;
  align-items: flex-start; }
  .header-desc__icon {
    margin-right: 20px; }
  .header-desc__text {
    margin: -5px -1px; }

.footer {
  position: relative;
  border: 1px solid #db0000;
  margin: 0 -1px -1px -1px;
  width: 900px;
  background: #ffffff;
  height: 43px;
  display: flex;
  justify-content: flex-end; }
  .footer__part {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: -1px; }
  .footer__help {
    position: absolute;
    left: -1px;
    bottom: -52px; }
  .footer--split {
    display: flex; }
    .footer--split .btn, .footer--split .react-add-to-calendar__button {
      margin-bottom: -1px; }

.content {
  display: flex;
  justify-content: flex-end;
  max-height: calc(100vh - 80px - 44px - 113px);
  overflow-y: scroll; }

.content__image {
  border-left: 1px solid #db0000;
  border-right: 1px solid #db0000;
  border-top: 1px solid #db0000;
  margin-left: -1px; }

.content__text {
  padding: 20px;
  margin: 0;
  margin-top: -4px; }

.content-single {
  display: flex;
  border-top: 1px solid #db0000;
  border-bottom: 1px solid #db0000;
  margin: -1px 0; }

.content-two {
  display: flex;
  border-top: 1px solid #db0000;
  border-bottom: 1px solid #db0000;
  margin: -1px 0;
  width: 50%; }

.content-sidebar {
  width: calc(25% + 1px);
  border-right: 1px solid #db0000; }
  .content-sidebar__item {
    border-bottom: 1px solid #db0000;
    padding: 20px; }
    .content-sidebar__item p {
      margin: 0; }
      .content-sidebar__item p:first-child {
        margin-top: -1px; }

.content-right {
  width: 75%; }

.content-description {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .content-description__item {
    border-bottom: 1px solid #db0000;
    padding: 20px;
    margin-bottom: -1px; }
    .content-description__item p {
      margin: -3px 0; }
      .content-description__item p + p {
        margin-top: 11px; }
    .content-description__item h2 {
      margin: -10px 0 10px 0; }
  .content-description__columns--two {
    display: flex; }
    .content-description__columns--two .column {
      width: 50%; }
      .content-description__columns--two .column + .column {
        padding-left: 20px; }
  .content-description .content-column {
    width: 50%; }
  .content-description--icon {
    display: flex; }

.content__box {
  width: 100%; }
  .content__box h2 {
    margin: 10px 18px -7px 18px; }
  .content__box + .content__box {
    border-top: 1px solid #db0000; }
  .content__box--helper-ballot {
    border-bottom: 1px solid #db0000; }

.content__square-icon {
  display: flex;
  justify-content: center;
  width: 75px;
  border: 1px solid #db0000;
  margin: 0 0 -1px -1px; }

.btn, .react-add-to-calendar__button {
  font-family: WhyteInktrapRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: normal;
  font-style: normal;
  border-radius: 0;
  border: 1px solid #db0000;
  color: #db0000;
  font-size: 0.9375rem;
  cursor: pointer;
  background-color: rgba(219, 0, 0, 0.1);
  line-height: 1.25rem;
  padding: 10px 19px 11px 19px;
  margin-left: -1px;
  margin-top: -1px;
  transition: background-color 0.1s ease-in-out; }
  .btn:hover, .react-add-to-calendar__button:hover {
    background-color: rgba(219, 0, 0, 0.2);
    border-color: #db0000; }
  .btn:focus, .react-add-to-calendar__button:focus {
    outline: 0; }
  .btn--primary {
    color: #ffffff;
    background-color: #db0000; }
    .btn--primary:hover {
      background-color: #a80000;
      border-color: #a80000; }
  .btn--small {
    padding: 4px 19px 4px 19px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #db0000; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #ffffff; }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #db0000; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #db0000; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: MogelsbergGroteskRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 0.8rem;
  background-color: #fff;
  color: #db0000;
  border: 1px solid #db0000;
  border-radius: 0;
  display: inline-block;
  position: relative; }

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px; }

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0; }

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem; }

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 1; }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: -1px; }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      display: none; }
  .react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px; }
  .react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px; }
    .react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px; }
    .react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #db0000;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  position: relative; }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #db0000;
  font-weight: normal;
  font-size: 0.9375rem; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-datepicker__navigation {
  background: none;
  line-height: 37px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden; }
  .react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__navigation--previous:hover {
      border-right-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #ff0f0f;
      cursor: default; }
  .react-datepicker__navigation--next {
    right: 10px;
    border-left-color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 80px; }
    .react-datepicker__navigation--next:hover {
      border-left-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #ff0f0f;
      cursor: default; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: rgba(219, 0, 0, 0.5); }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: rgba(219, 0, 0, 0.5); }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: rgba(168, 0, 0, 0.5); }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: 85px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #db0000;
  width: 70px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (37px / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          -webkit-font-feature-settings: "tnum";
                  font-feature-settings: "tnum";
          font-variant-numeric: tabular-nums; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item::before {
            display: none; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: rgba(219, 0, 0, 0.1);
            border: 1px solid #db0000;
            color: #db0000;
            margin-left: -1px;
            transition: background-color 0.1s ease-in-out; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: rgba(219, 0, 0, 0.2); }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            display: none;
            color: rgba(219, 0, 0, 0.5); }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: rgba(219, 0, 0, 0.5);
  display: inline-block;
  width: 37px;
  line-height: 37px;
  text-align: center;
  margin: 0; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 0;
      background-color: #ffffff; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #db0000;
  display: inline-block;
  width: 37px;
  line-height: 37px;
  text-align: center;
  margin: 0; }

.react-datepicker__day-name {
  font-size: 0.6875rem; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 0;
  background-color: #db0000;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: #c20000; }

.react-datepicker__month--disabled {
  color: rgba(219, 0, 0, 0.5);
  pointer-events: none; }
  .react-datepicker__month--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    border-radius: 0;
    background-color: #ffffff; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted {
    border-radius: 0;
    background-color: #db0000;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover {
      background-color: #c20000; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    border-radius: 0;
    background-color: rgba(219, 0, 0, 0.1);
    color: #db0000;
    border: 1px solid #db0000;
    transition: background-color 0.1s ease-in-out; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover {
      background-color: rgba(219, 0, 0, 0.2); }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    border-radius: 0;
    background-color: rgba(219, 0, 0, 0.1);
    border: 1px solid #db0000;
    color: #db0000;
    transition: background-color 0.1s ease-in-out; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover {
      background-color: rgba(219, 0, 0, 0.2); }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range {
    background-color: rgba(219, 0, 0, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range {
    background-color: #ffffff;
    color: #db0000; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled {
    cursor: default;
    color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #db0000; }

.react-datepicker__month-text:hover {
  background-color: #ffffff; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: rgba(168, 0, 0, 0.5); }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: rgba(219, 0, 0, 0.5);
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #ffffff;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0;
  border: 1px solid #db0000; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: rgba(168, 0, 0, 0.5); }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }
  .react-datepicker__close-icon::after {
    background-color: #db0000;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\D7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center; }

.react-datepicker__today-button {
  background: #ffffff;
  border-top: 1px solid #db0000;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    font-size: 0.9375rem;
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: #ff0f0f;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: rgba(219, 0, 0, 0.5); }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: rgba(168, 0, 0, 0.5); }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: #ff0f0f;
      cursor: default; }

.vote-add {
  display: flex; }

.vote {
  display: flex;
  width: 100%;
  border: 1px solid #db0000;
  border-right: 0;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 20px;
  padding-right: 0;
  justify-content: space-between;
  align-items: flex-end; }
  .vote__reserve-button {
    border-right: 0;
    margin-bottom: -21px; }
  .vote:first-child {
    margin-top: 20px; }

.vote-prop {
  display: flex;
  flex-direction: column; }
  .vote-prop__time {
    margin-top: -3px; }
  .vote-prop__location {
    margin: 13px 0 -5px 0; }

.react-add-to-calendar {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-right: -1px;
  margin-bottom: -1px; }
  .react-add-to-calendar__button {
    display: flex;
    padding: 4px 19px 4px 19px;
    margin-right: -20px;
    margin-bottom: -20px;
    margin-top: 15px;
    font-family: WhyteInktrapRegular, "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Segoe UI, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
    .react-add-to-calendar__button span {
      font-size: 0.9375rem; }
  .react-add-to-calendar__dropdown {
    width: 100%;
    border: 1px solid #db0000;
    margin-right: -20px;
    margin-top: 19px;
    margin-left: -1px; }
    .react-add-to-calendar__dropdown ul {
      margin: 9px 0; }
      .react-add-to-calendar__dropdown ul li {
        padding: 6px 20px; }
        .react-add-to-calendar__dropdown ul li::before {
          display: none; }

.loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 100; }

.loader-box {
  display: flex;
  width: 450px;
  border: 1px solid #db0000;
  background-color: #ffffff;
  align-items: flex-start; }
  .loader-box__image {
    border-right: 1px solid #db0000; }
  .loader-box__text {
    margin: 16px 19px; }

.modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
  background-color: rgba(255, 255, 255, 0.7); }

.modal__body {
  display: flex;
  width: 450px;
  border: 1px solid #db0000;
  background-color: #ffffff;
  align-items: flex-start;
  margin-bottom: -1px; }

.modal__image {
  border-right: 1px solid #db0000; }

.modal__text {
  margin: 16px 19px; }

.modal__footer {
  background-color: #ffffff;
  border: 1px solid #db0000;
  display: flex;
  justify-content: flex-end; }
  .modal__footer .btn, .modal__footer .react-add-to-calendar__button {
    margin-right: -1px; }

.vote-list-item {
  background-color: #ebebeb; }

