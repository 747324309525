form {
    display: flex;
    width: 100%;
}

.form-group {
    display: flex;
    flex-direction: column;
    width: 37.5%;
    margin-bottom: $padding-base;

    & + .form-group {
        margin-left: -$border-width;
    }

    &__helper {
        margin-top: 13px;
        margin-bottom: -6px;
        line-height: 20px;
    }
}

label {
    z-index: 2;
    padding-left: 20px;
    margin-top: 37px;
}

.react-datepicker__input-container {
    width: 100%;
}

textarea:focus, 
input:focus{
    outline: none;
}

input[type="text"] {
    height: 70px;
    border: $border;
    width: 100%;
    color: $color-brand;
    font-size: 15px;
    padding-left: $padding-base;
    padding-top: $padding-base;
    padding: 20px;
    padding-top: 35px;
    padding-left: 19px;
    margin-top: -32px;
}