.react-add-to-calendar {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: -$border-width;
    margin-bottom: -$border-width;
    
    &__button {
        @extend .btn;
        display: flex;
        padding: 4px 19px 4px 19px;
        margin-right: -$padding-base;
        margin-bottom: -$padding-base;
        margin-top: 15px;
        font-family: WhyteInktrapRegular, $font-fallback;
        
        span {
            font-size: rem(15px);
        }
    }

    &__dropdown {
        width: 100%;
        border: $border;
        margin-right: -$padding-base;
        margin-top: $padding-base -$border-width;
        margin-left: -$border-width;

        ul {
            margin: 9px 0;

            li {
                padding: 6px $padding-base;
    
                &::before {
                    display: none;
                }
            }
        }
    }
}